.MiTNM-sidebar {
    z-index: 10;
    background-color: #fff;
    padding-right: 28px;
}

.MiTNM-sidebar-enter {
    width: 25% !important;
}

.MiTNM-sidebar-enter-active {
    width: 50% !important;
    transition: width 500ms;
}

.MiTNM-sidebar-enter-done {
    width: 50% !important;
}

.MiTNM-sidebar-exit {
    width: 50% !important;
}

.MiTNM-sidebar-exit-active {
    width: 25% !important;
    transition: width 500ms;
}

.MiTNM-sidebar-exit-done {
    width: 25% !important;
}

.MiTNM-content-enter {
    margin-left: -25%;
}

.MiTNM-content-enter-active {
    margin-left: 0;
    transition: margin-left 500ms;
}

.MiTNM-content-enter-done {
    margin-left: 0;
}

.MiTNM-content-exit {
    margin-left: 0;
}

.MiTNM-content-exit-active {
    margin-left: -25%;
    transition: margin-left 500ms;
}

.MiTNM-content-exit-done {
    margin-left: -25%;
}