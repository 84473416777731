.sidebar-nav-subsection {
  padding-left: 2rem;
}

/* xxl */
@media (min-width: 1400px) {
  .sidebar-nav-subsection {
    padding-left: 3rem;
  }
}

.sidebar-nav-link {
  padding: 0.25rem 0.5rem !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  background-color: transparent !important;
  border: 0px !important;
}
.sidebar-nav-link:hover,
.sidebar-nav-link:focus,
.sidebar-nav-link.active:hover,
.sidebar-nav-link.active:focus {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: var(--bs-primary-dark) !important;
}

.sidebar-nav-link.active {
  background-color: transparent !important;
  color: var(--bs-primary) !important;
}

.sidebar-nav-link.disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}
