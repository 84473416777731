@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

:root {
  --bs-font-sans-serif: "Poppins";
  --bs-body-font-weight: 500;
  --bs-primary: #e53700;
  --bs-primary-rgb: 229, 55, 0;
  --bs-primary-dark: #c22e00; /* 15% darker */
  --bs-primary-light: #ff5d26; /* 15% lighter */
}

input::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.btn-primary {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-primary:hover {
  background-color: var(--bs-primary-dark);
  border-color: var(--bs-primary-dark);
}

.btn-primary:disabled {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-outline-primary {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-info {
  color: #fff;
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}
.btn-info:hover {
  color: #fff;
  background-color: var(--bs-info-light-15);
  border-color: var(--bs-info-light-10);
}
.btn-info:disabled,
.btn-info.disabled {
  color: #fff;
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--bs-primary);
  background-color: #f5f5f5;
}

a,
.btn-link {
  color: var(--bs-primary);
}

.nav-link {
  color: var(--bs-primary);
}

.nav-link:focus,
.nav-link:hover,
.btn-link:hover {
  color: var(--bs-primary-dark);
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link.active {
  color: #fff;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--bs-primary);
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff;
  background-color: var(--bs-primary);
}

.nav-pills .nav-link {
  color: var(--bs-secondary);
  border: 1px solid var(--bs-secondary);
  border-radius: 0.25rem;
}

.dropdown-toggle::before {
  display: none;
}
.dropdown-toggle::after {
  display: none;
}

.carousel-control-next,
.carousel-control-prev {
  width: 7.5%;
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.carousel-indicators {
  display: none;
}

.form-control[readonly] {
  background-color: transparent;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-calendar {
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6 !important;
}

.react-calendar__tile--now {
  background: #fff !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #e6e6e6 !important;
}

.react-calendar__tile--hasActive {
  background: var(--bs-primary-light) !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--bs-primary) !important;
}

.react-calendar__tile--active {
  background: var(--bs-primary) !important;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--bs-primary-dark) !important;
}

.text-indigo {
  color: var(--bs-indigo) !important;
}
